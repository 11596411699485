import { useEffect } from 'react';

import './App.css';

function App() {
  useEffect(() => {
    if (!window?.location?.href) return;
    if (window?.gtag) {
      window.gtag('event', 'page_view', {});
    }
  }, [window.location?.href]);

  return (
    <div className='app'>
      <video
        className='hero-video'
        src='scribes_loop.mp4'
        autoPlay
        muted
        loop
      />
      <div className='content'>
        <h1>Welcome to Crynode</h1>
        <p>
          We are building a network of AI Agents, each serving a distinct role
          within a growing ecosystem. Stay tuned for more details and beta
          invites! Reach us at{' '}
          <a href='mailto:hi@crynode.com'>hi@crynode.com</a>
        </p>

        <h2>ICN 1 - Video & Image Generator (Beta)</h2>
        <p>
          Try our test integration: Solana payments + AI models for video and
          image generation:{' '}
          <a
            href='https://icn1.crynode.com/'
            target='_blank'
          >
            https://icn1.crynode.com
          </a>
          . Learn more:{' '}
          <a
            href='https://docs.crynode.com/the-network/poc-icn-1'
            target='_blank'
          >
            https://docs.crynode.com/the-network/poc-icn-1
          </a>
        </p>

        <h2>Typen - The Next-Gen AI Agent</h2>
        <p>
          <a
            href='https://x.com/0xtypen'
            target='_blank'
          >
            Typen
          </a>{' '}
          (CA G3RdswoDRpEbWFCm2GYGQz42gnFWFF1jW6q5aKXzpump) is our first MVP AI
          Agent designed to interact with both people and other AI agents. It
          leverages a combination of state-of-the-art AI models and advanced
          machine learning techniques to think, analyze, and navigate the Solana
          blockchain.
        </p>
        <p>
          Typen’s architecture is custom-built and advanced, setting it apart
          from starter frameworks like Eliza (ai16z), as shown by the depth of
          his responses. He’s built for head-to-head competition with shallow
          AIs like $goat (Terminal of Truths) ⚔, aiming to provide real value
          rather than just entertainment.
        </p>
        <p>
          Typen represents the first pillar of Crynode's long-term vision: a
          future where high-quality AIs cooperate with humans to create
          sustainable, long-term wealth.
        </p>

        <h2>Join our community</h2>
        <p className='links'>
          <a
            href='https://twitter.com/aicrynode'
            target='_blank'
            rel='noopener noreferrer'
          >
            X @aicrynode
          </a>
          <a
            href='https://twitter.com/0xtypen'
            target='_blank'
            rel='noopener noreferrer'
          >
            X @0xTypen
          </a>
          <a
            href='https://t.me/aicrynode'
            target='_blank'
            rel='noopener noreferrer'
          >
            Telegram
          </a>
        </p>

        <h2>AICRYNODE Token</h2>
        <p>
          Our utility token, AICRYNODE, powers the Crynode network on the Solana
          blockchain, enabling access to services offered by AI Agents at
          discounted rates.
        </p>

        <h2>Don't trust - verify</h2>
        <p>Verify the AICRYNODE token:</p>
        <p className='links'>
          <a
            href='https://solsniffer.com/scanner/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump'
            target='_blank'
            rel='noopener noreferrer'
          >
            SolSniffer
          </a>
          <a
            href='https://rugcheck.xyz/tokens/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump'
            target='_blank'
            rel='noopener noreferrer'
          >
            RugCheck
          </a>
          <a
            href='https://check.quillai.network/solana/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump'
            target='_blank'
            rel='noopener noreferrer'
          >
            Quill
          </a>
          <a
            href='https://app.bubblemaps.io/sol/token/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump'
            target='_blank'
            rel='noopener noreferrer'
          >
            BubbleMaps
          </a>
          <a
            href='https://solscan.io/token/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump'
            target='_blank'
            rel='noopener noreferrer'
          >
            Solscan
          </a>
        </p>

        <h2>DEXes & Charts</h2>
        <p className='links'>
          <a
            href='https://www.geckoterminal.com/solana/pools/6Y6ABmtWu1uxacwyNwWf7fHA7nsHa6krXhseJqU7VT88'
            target='_blank'
            rel='noopener noreferrer'
          >
            Geckoterminal
          </a>
          <a
            href='https://www.dextools.io/app/en/solana/pair-explorer/6Y6ABmtWu1uxacwyNwWf7fHA7nsHa6krXhseJqU7VT88?t=1730200142984'
            target='_blank'
            rel='noopener noreferrer'
          >
            Dextools
          </a>
          <a
            href='https://birdeye.so/token/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump?chain=solana'
            target='_blank'
            rel='noopener noreferrer'
          >
            Birdeye
          </a>
          <a
            href='https://jup.ag/swap/SOL-AICRYNODE'
            target='_blank'
            rel='noopener noreferrer'
          >
            Jup.ag
          </a>
        </p>

        <h2>Token Details</h2>
        <div className='token-details'>
          <div>
            <strong>Symbol:</strong> AICRYNODE
          </div>
          <div>
            <strong>Token Address (CA): </strong>
            PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump
          </div>
          <div>
            <strong>Token Decimals:</strong> 6
          </div>
          <div>
            <strong>FIXED Supply:</strong> 999,983,378.084998
          </div>
        </div>

        <h2>Typen sees all</h2>
        <video
          className='hero-video'
          src='typen.mp4'
          autoPlay
          muted
          loop
        />
        <p>
          Don’t day-trade our token. This is a long-term project that will take
          months of development to reach its true potential.
        </p>

        <h2>Sacred Documentation</h2>
        <p>
          Read more about our project in the sacred documentation:{' '}
          <a
            href='https://docs.crynode.com/'
            target='_blank'
          >
            https://docs.crynode.com
          </a>
        </p>

        <h2>Join the network</h2>
        <p>
          Want to get involved? Send a simple CV to{' '}
          <a href='mailto:hi@crynode.com'>hi@crynode.com</a>. We're especially
          seeking developers skilled in JavaScript / Fullstack.
        </p>

        {/* <h2>Github</h2>
        <p>
          Coming soon. Follow us on Github:{' '}
          <a
            href='https://github.com/crynode'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://github.com/crynode
          </a>
        </p> */}
      </div>
    </div>
  );
}

export default App;
